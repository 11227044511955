import React from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import axios from "axios";
import history from "app/routes/history";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

// Redux actions
import { logoutUser } from "store/actions/generalActions";

class Header extends React.Component {
    state = {};

    handleLogout = async () => {
        const res = await axios.get(process.env.REACT_APP_BACKEND + "/logout");

        if (!res || res.status !== 200) throw new Error("Could not logout properly.");

        const cookies = new Cookies();
        cookies.remove("jwtToken", { path: "/" });
        this.props.logoutUser();
        history.push("/login");
    };

    render() {
        const name = this.props.userDetail.first_name || "Account";

        return (
            <Navbar fixed="top" bg="light" expand="lg" id="navbar" className={this.props.userAuth ? '' : 'navbar-no-side'}>
                {this.props.userAuth && (
                    <>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse className="justify-content-end">
                            <Nav>
                                <NavDropdown alignRight title={name} id="basic-nav-dropdown">
                                    <NavDropdown.Item onClick={this.handleLogout}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </>
                )}
            </Navbar>
        );
    }
}

const mapStateToProps = state => ({
    userAuth: state.general.userAuth,
    userDetail: state.general.userDetail
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Header);
