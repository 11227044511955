import { AUTHENTICATED_USER, LOGOUT_USER, VERSION } from "store/constants/types";
import produce from "immer";

const initialState = {
    version: null,
    userAuth: false,
    userDetail: {}
};

export default produce((draft = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATED_USER:
            if (!action.payload.user) {
                draft.userAuth = false;
                draft.userDetail = {};
            } else {
                draft.userAuth = true;
                draft.userDetail = action.payload.user;
            }

            return draft;
        case LOGOUT_USER:
            draft.userAuth = false;
            draft.userDetail = {};
            return draft;
        case VERSION:
            draft.version = action.payload.version || "0";
            return draft;
        default:
            return draft;
    }
});
