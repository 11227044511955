import React from "react";
import ReactDOM from "react-dom";
import { unregister } from "serviceWorker";
import { Provider } from "react-redux";
import store from "./store";

import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import App from "app/App";

Chart.plugins.unregister(ChartDataLabels);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

unregister();
