import React from "react";
import { loader } from "graphql.macro";

const Login                    = React.lazy(() => import("pages/Login"));
const Dashboard                = React.lazy(() => import("pages/Dashboard"));
const Leaderboard              = React.lazy(() => import("pages/Leaderboard"));
const AskFlagList              = React.lazy(() => import("pages/AskFlagList"));
const FlagList                 = React.lazy(() => import("pages/FlagList"));
const QuestionList             = React.lazy(() => import("pages/QuestionList"));
const Curriculum               = React.lazy(() => import("pages/Curriculum/Curriculum"));
const CurriculumBookDetail     = React.lazy(() => import("pages/Curriculum/CurriculumBookDetail"));
const CurriculumChapterDetail  = React.lazy(() => import("pages/Curriculum/CurriculumChapterDetail"));
const CurriculumSectionDetail  = React.lazy(() => import("pages/Curriculum/CurriculumSectionDetail"));
const CurriculumEdit           = React.lazy(() => import("pages/Curriculum/edit/CurriculumEdit"));
const CurriculumTopicEdit      = React.lazy(() => import("pages/Curriculum/edit/CurriculumTopicEdit"));
const CurriculumSubTopicEdit   = React.lazy(() => import("pages/Curriculum/edit/CurriculumSubTopicEdit"));
const BookList                 = React.lazy(() => import("pages/BookList"));
const BookDetail               = React.lazy(() => import("pages/BookDetail"));
const ChapterDetail            = React.lazy(() => import("pages/ChapterDetail"));
const SectionDetail            = React.lazy(() => import("pages/SectionDetail"));
const QuestionDetail           = React.lazy(() => import("pages/QuestionDetail"));
const AskQuestionDetail        = React.lazy(() => import("pages/AskQuestionDetail"));
const EducatorList             = React.lazy(() => import("pages/EducatorList"));
const EducatorDetail           = React.lazy(() => import("pages/EducatorDetail"));
const StudentList              = React.lazy(() => import("pages/StudentList"));
const StudentDetail            = React.lazy(() => import("pages/StudentDetail"));
const TestPrepList             = React.lazy(() => import("pages/TestPrepList"));
const TestPrepDetail           = React.lazy(() => import("pages/TestPrepDetail"));
const TestPrepChapterDetail    = React.lazy(() => import("pages/TestPrepChapterDetail"));
const TestPrepExamList         = React.lazy(() => import("pages/TestPrepExamList"));
const TestPrepExamDetail       = React.lazy(() => import("pages/TestPrepExamDetail"));
const AffiliateList            = React.lazy(() => import("pages/AffiliateList"));
const AffiliateDetail          = React.lazy(() => import("pages/AffiliateDetail"));
const ReferralList             = React.lazy(() => import("pages/ReferralList"));
const ReferralDetail           = React.lazy(() => import("pages/ReferralDetail"));
const CommentList              = React.lazy(() => import("pages/CommentList"));
const CourseList               = React.lazy(() => import("pages/CourseList"));
const CourseVideosList         = React.lazy(() => import("pages/CourseVideoList"));
const CourseVideoDetail        = React.lazy(() => import("pages/CourseVideoDetail"));
const OfficeHoursList          = React.lazy(() => import("pages/OfficeHoursList"));
const OfficeHoursSectionDetail = React.lazy(() => import("pages/OfficeHoursSectionDetail"));
const ReactivationRequestsList = React.lazy(() => import("pages/ReactivationRequestsList"));
const UTMGenerator             = React.lazy(() => import("pages/UTMGenerator"));
const ProspectList             = React.lazy(() => import("pages/ProspectList"));
const AssignmentRedoUpload     = React.lazy(() => import("pages/CSVUpload"));
const AssignmentOutstanding    = React.lazy(() => import("pages/AssignmentsOutstanding"));
const AssignmentsList          = React.lazy(() => import("pages/AssignmentsList"));
const AssignmentDetail         = React.lazy(() => import("pages/AssignmentDetail"));
const PriceControl             = React.lazy(() => import("pages/PriceControl"));
const BonusControl             = React.lazy(() => import("pages/BonusControl"));

export const routes = [
    { path: "/login", exact: true, component: Login },
    { path: "/dashboard", exact: true, component: Dashboard, query: loader("../../.graphql_queries/dashboard/dashboard_stats.gql") },
    { path: "/leaderboard/:type", exact: true, component: Leaderboard, permissions: ["staff", "admin"] },
    {
        path: "/askflag/list/:page/:count/",
        component: AskFlagList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/flag/list/ask_flag_list.gql")
    },
    {
        path: "/flag/list/:page/:count/",
        component: FlagList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/flag/list/flag_list.gql")
    },
    {
        path: "/question/list/:page/:count/:status?",
        component: QuestionList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/question/list/question_list.gql")
    },
    {
        path: "/curriculum/:page/:count/",
        component: Curriculum,
        exact: true,
        permissions: ["staff", "admin", "tagging"]
    },
    {
        path: "/curriculum/book/detail/:bookId/:page/:count/",
        component: CurriculumBookDetail,
        exact: true,
        permissions: ["staff", "admin", "tagging"]
    },
    {
        path: "/curriculum/chapter/detail/:chapterId/:page/:count/",
        component: CurriculumChapterDetail,
        exact: true,
        permissions: ["staff", "admin", "tagging"]
    },
    {
        path: "/curriculum/section/detail/:sectionId/:page/:count/",
        component: CurriculumSectionDetail,
        exact: true,
        permissions: ["staff", "admin", "tagging"]
    },
    {
        path: "/curriculum/edit/:page/:count/",
        component: CurriculumEdit,
        exact: true,
        permissions: ["staff", "admin", "tagging"]
    },
    {
        path: "/curriculum/:curriculumId/topics/edit/:page/:count/",
        component: CurriculumTopicEdit,
        exact: true,
        permissions: ["staff", "admin", "tagging"]
    },
    {
        path: "/curriculum/:curriculumId/topics/:topicId/edit/:page/:count/",
        component: CurriculumSubTopicEdit,
        exact: true,
        permissions: ["staff", "admin", "tagging"]
    },
    {
        path: "/book/list/:page/:count/",
        component: BookList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/book/list/book_list.gql")
    },
    {
        path: "/book/detail/:bookId/:page/:count/",
        component: BookDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/book/detail/book_detail.gql")
    },
    {
        path: "/chapter/detail/:chapterId/:page/:count/",
        component: ChapterDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/chapter/detail/chapter_detail.gql")
    },
    {
        path: "/section/detail/:sectionId/:page/:count/",
        component: SectionDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/section/detail/section_detail.gql")
    },
    {
        path: "/question/detail/:questionId",
        component: QuestionDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/question/detail/question_detail.gql")
    },
    {
        path: "/ask-question/detail/:askQuestionId",
        component: AskQuestionDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/numerask/detail/ask_question_detail.gql")
    },
    {
        path: "/educator/list/:page/:count/",
        component: EducatorList,
        exact: true,
        permissions: ["staff", "admin"],
        // query: loader("../../.graphql_queries/educator/list/educator_list.gql")
    },
    {
        path: "/educator/detail/:educatorId/:page/:count/:tab",
        component: EducatorDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/educator/detail/educator_detail.gql")
    },
    {
        path: "/student/list/:page/:count/",
        component: StudentList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/student/list/student_list.gql")
    },
    {
        path: "/student/detail/:studentId/:page/:count",
        component: StudentDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/student/detail/student_detail.gql")
    },
    {
        path: "/test-prep/list/:page/:count/",
        component: TestPrepList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/testprep/list/testprep_list.gql")
    },
    {
        path: "/test-prep/book/detail/:testprepBookId/:page/:count/",
        component: TestPrepDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/testprep/detail/testprep_detail.gql")
    },
    {
        path: "/test-prep/chapter/detail/:testprepChapterId/:page/:count/",
        component: TestPrepChapterDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/testprep/chapter/testprep_chapter_detail.gql")
    },
    {
        path: "/test-prep/exam/list/:page/:count/",
        component: TestPrepExamList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/testprepexam/list/testprepexam_list.gql")
    },
    {
        path: "/test-prep/exam/detail/:testprepExamId/:page/:count/",
        component: TestPrepExamDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/testprepexam/detail/testprepexam_detail.gql")
    },
    {
        path: "/referral/list/:page/:count/",
        component: ReferralList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/referral/list/referral_list.gql")
    },
    {
        path: "/referral/detail/:refereeId/:page/:count/",
        component: ReferralDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/referral/detail/referee_detail.gql")
    },
    {
        path: "/comment/list/:page/:count/",
        component: CommentList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/comment/list/comment_list.gql")
    },
    {
        path: "/course/list/:page/:count/",
        component: CourseList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/course/list/course_list.gql")
    },
    {
        path: "/assignments/list/:page/:count/",
        component: AssignmentsList,
        exact: true,
        permissions: ["staff", "admin", "qa"],
    },
    {
        path: "/price/list/:page/:count/",
        component: PriceControl,
        exact: true,
        permissions: ["staff", "admin"],
    },
    {
        path: "/bonus/list/:page/:count/",
        component: BonusControl,
        exact: true,
        permissions: ["staff", "admin"]
    },
    {
        path: "/coursevideos/list/:page/:count/",
        component: CourseVideosList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/coursevideos/list/coursevideos_list.gql")
    },
    {
        path: "/coursevideos/detail/:courseVideoId/:page/:count/",
        component: CourseVideoDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/coursevideos/detail/coursevideo_detail.gql")
    },
    {
        path: "/officehours/list/:page/:count/",
        component: OfficeHoursList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/officehours/list/officehours_list.gql")
    },
    {
        path: "/officehours/detail/:sectionId/:page/:count/",
        component: OfficeHoursSectionDetail,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/officehours/detail/officehours_section_detail.gql")
    },
    {
        path: "/utm-generator",
        component: UTMGenerator,
        exact: true,
        permissions: ["staff", "admin"]
    },
    {
        path: "/assignment-redo/",
        component: AssignmentRedoUpload,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/prospects/list/prospects_list.gql")
    },
    {
        path: "/assignment-outstanding/",
        component: AssignmentOutstanding,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/prospects/list/prospects_list.gql")
    },
    {
        path: "/assignment/detail/:id",
        component: AssignmentDetail,
        exact: true,
        permissions: ["staff", "admin", "qa"],
    },
    {
        path: "/reactivation/list/:page/:count/",
        component: ReactivationRequestsList,
        exact: true,
        permissions: ["staff", "admin"],
        query: loader("../../.graphql_queries/educator/list/educator_list.gql")
    }
];
