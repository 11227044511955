import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";

import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Toast from "react-bootstrap/Toast";
import Row from "react-bootstrap/Row";

import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

class Sidebar extends React.Component {
    state = {
        userPermissions: null,
        items: [
            {
                name: "Stats",
                isLink: false,
                path: "/dashboard",
                match: ["/dashboard", "/leaderboard", "/referral", "/referral/detail"],
                permissions: ["staff", "admin"],
                subtopics: [
                    {
                        name: "Dashboard",
                        path: "/dashboard",
                        match: ["/dashboard"],
                        permissions: ["staff", "admin"],
                    },
                    {
                        name: "Leaderboard",
                        path: "/leaderboard/educators",
                        match: ["/leaderboard"],
                        permissions: ["staff", "admin"],
                    },
                    {
                        name: "Referrals Leaderboard",
                        path: "/referral/list/1/25",
                        match: ["/referral", "/referral/detail"],
                        permissions: ["staff", "admin"],
                    }
                ]
            },
            {
                name: "Content",
                isLink: false,
                path: "/question/list/1/25",
                permissions: ["staff", "admin", "tagging"],
                match: [
                    "/curriculum",
                    "/book/list",
                    "/book/detail",
                    "/chapter/detail",
                    "/section/detail",
                    "/question/detail",
                    "/question/list",
                    "/test-prep/list",
                    "/test-prep/book",
                    "/test-prep/chapter",
                    "/test-prep/exam",
                    "/utm-generator"
                ],
                subtopics: [
                    {
                        name: "Curriculum",
                        path: "/curriculum/1/10",
                        match: ["/curriculum"],
                        permissions: ["staff", "admin", "tagging"]
                    },
                    {
                        name: "Books",
                        path: "/book/list/1/25",
                        match: ["/book/list", "/book/detail", "/chapter/detail", "/section/detail", "/question/detail"],
                        permissions: ["staff", "admin"]
                    },
                    {
                        name: "Questions",
                        path: "/question/list/1/25",
                        match: ["/question/list"],
                        permissions: ["staff", "admin"]
                    },
                    {
                        name: "Test Prep Books",
                        path: "/test-prep/list/1/25",
                        match: ["/test-prep/list", "/test-prep/book", "/test-prep/chapter"],
                        permissions: ["staff", "admin"]
                    },
                    {
                        name: "Test Prep Exams",
                        path: "/test-prep/exam/list/1/25",
                        match: ["/test-prep/exam"],
                        permissions: ["staff", "admin"]
                    },
                    {
                        name: "UTM Generator",
                        path: "/utm-generator",
                        match: ["/utm-generator"],
                        permissions: ["staff", "admin"]
                    },
                    {
                        name: "Assignment Redo",
                        path: "/assignment-redo",
                        match: ["/assignment-redo"],
                        permissions: ["staff", "admin"]
                    },
                    {
                        name: "Assignment Outstanding",
                        path: "/assignment-outstanding",
                        match: ["/assignment-outstanding"],
                        permissions: ["staff", "admin"]
                    }
                ]
            },
            {
                name: "Feedback",
                isLink: false,
                path: "/comment/list/1/25",
                match: ["/comment/list", "/flag/list", "/askflag/list"],
                permissions: ["staff", "admin"],
                subtopics: [
                    {
                        name: "Comments",
                        path: "/comment/list/1/25",
                        match: ["/comment/list"],
                        permissions: ["staff", "admin"]
                    },
                    {
                        name: "Textbook Flags",
                        path: "/flag/list/1/25",
                        match: ["/flag/list"],
                        permissions: ["staff", "admin"]
                    },
                    {
                        name: "Ask Flags",
                        path: "/askflag/list/1/25",
                        match: ["/askflag/list"],
                        permissions: ["staff", "admin"]
                    }
                ]
            },
            {
                name: "Payouts",
                isLink: false,
                path: "/assignments/list/1/25",
                match: ["/assignments/list/", "/bonus/list", "/price/list"],
                permissions: ["staff", "admin", "qa"],
                subtopics: [
                    {
                        name: "Assignment List",
                        path: "/assignments/list/1/25",
                        match: ["/assignments/list"],
                        permissions: ["staff", "admin", "qa"]
                    },
                    {
                        name: "Bonus Control",
                        path: "/bonus/list/1/25",
                        match: ["/bonus/list"],
                        permissions: ["staff", "admin"]
                    },
                    {
                        name: "Price Control",
                        path: "/price/list/1/25",
                        match: ["/price/list"],
                        permissions: ["staff", "admin"]
                    }
                ]
            },
            {
                name: "People",
                isLink: false,
                path: "/educator/list/1/25",
                match: ["/educator", "/educator/detail", "/student", "/student/detail"],
                permissions: ["staff", "admin"],
                subtopics: [
                    {
                        name: "Educators",
                        path: "/educator/list/1/25",
                        match: ["/educator", "/educator/detail"],
                        permissions: ["staff", "admin"]
                    },
                    {
                        name: "Students",
                        path: "/student/list/1/25",
                        match: ["/student", "/student/detail"],
                        permissions: ["staff", "admin"]
                    },
                ]
            },
            {
                name: "Courses",
                isLink: false,
                path: "/course/list/1/25",
                match: ["/course/list", "/coursevideos/list"],
                permissions: ["staff", "admin"],
                subtopics: [
                    {
                        name: "Course Order",
                        path: "/course/list/1/25",
                        match: ["/course/list"],
                        permissions: ["staff", "admin"]
                    },
                    {
                        name: "Course Videos",
                        path: "/coursevideos/list/1/25",
                        match: ["/coursevideos/list"],
                        permissions: ["staff", "admin"]
                    }
                ]
            },
            {
                name: "Office Hours",
                path: "/officehours/list/1/25",
                match: ["/officehours/list"],
                permissions: ["staff", "admin"]
            },
            {
                name: "Reactivation Requests",
                path: "/reactivation/list/1/25",
                match: ["/reactivation/list"],
                permissions: ["staff", "admin"]
            },
            {
                name: "Assignment Outstanding",
                path: "/assignment-outstanding",
                match: ["/assignment-outstanding"],
                permissions: ["staff", "admin"]
            }
        ],
        activeItem: "Dashboard",
        activeSubTopic: "",
        showVersionToast: false
    };

    componentDidMount() {
        this.getPermissions();
        this.updateActiveItem();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.updateActiveItem();
        }

        if (
            prevProps.version !== this.props.version &&
            process.env.REACT_APP_VERSION !== this.props.version &&
            !this.state.showVersionToast
        ) {
            this.setState({ showVersionToast: true });
        }
    }

    updateActiveItem = () => {
        const { pathname } = this.props.location;
        let activeItem = _.find(this.state.items, o => o.match.some(p => pathname.includes(p)));
        let activeSubTopic = "";
        if (activeItem) {
            if (activeItem.subtopics) {
                let activeSubTopic = _.find(activeItem.subtopics, o => o.match.some(p => pathname.includes(p)));
                if (activeSubTopic) {
                    this.setState({ activeSubTopic: activeSubTopic.name });
                }
            }
        }
        this.setState({ activeItem: activeItem ? activeItem.name : null });
    };

    refreshApp = () => {
        window.location.reload(true);
    };

    toggleVersionToast = () => this.setState(state => ({ showVersionToast: !state.showVersionToast }));

    handleActiveItem = e => {
        let text = e.currentTarget.textContent;
        this.setState({ activeItem: text, activeSubTopic: "educators" });
    };

    handleActiveSubItem = e => {
        let text = e.currentTarget.textContent;
        this.setState({ activeSubTopic: text });
    };

    getPermissions = () => {
        const cookies = new Cookies();
        const token = cookies.get("jwtToken");
        let user = jwtDecode(token);
        let userPermissions = user.permissions;
        this.setState({ userPermissions })
    }

    render() {
        return (
            <>
                <div className="bg-light border-right position" id="sidebar-wrapper">
                    <div className="sidebar-heading logo2">
                        <Image src={process.env.REACT_APP_LOGO2} width={150} />
                    </div>
                    <div className="list-group list-group-flush">
                        {this.state.items.map((item, i) => {
                            if (!this.state.userPermissions || !this.state.userPermissions.some(perm => item.permissions.includes(perm))) { return }
                            const active = this.state.activeItem === item.name ? "active" : "inactive";
                            if (item.subtopics && active == "active") {
                                return (
                                    <div key={i}>
                                        <Link
                                            className={`list-group-item list-group-item-action bg-light ${active}`}
                                            // to={this.state.activeSubTopic.path}
                                            onClick={this.handleActiveItem}
                                            style={{ zIndex: 10 }}
                                        >
                                            <Row className="w-100 m-0 p-0">
                                                <span className="mr-4">{item.name}</span>
                                            </Row>
                                        </Link>
                                        <div className="animated slideInDown faster subitems" style={{ zIndex: -10 }}>
                                            {item.subtopics.map((topic, i2) => {
                                                if (!this.state.userPermissions || !this.state.userPermissions.some(perm => topic.permissions.includes(perm))) { return }
                                                const activeSub =
                                                    this.state.activeSubTopic.toLowerCase() === topic.name.toLowerCase()
                                                        ? "active"
                                                        : "inactive";

                                                return (
                                                    <Link
                                                        className={`d-flex align-items-center sub list-group-item list-group-item-action bg-light ${activeSub}`}
                                                        to={topic.path}
                                                        onClick={this.handleActiveSubItem}
                                                        key={i2}
                                                    >
                                                        <span className="d-flex">{topic.name}</span>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <Link
                                        className={`list-group-item list-group-item-action bg-light ${active}`}
                                        to={item.path}
                                        onClick={this.handleActiveItem}
                                        key={item.name + i}
                                        style={{ zIndex: 10 }}
                                    >
                                        <span>{item.name}</span>
                                    </Link>
                                );
                            }
                        })}
                    </div>
                    <Container className="text-center px-5 version">
                        <small className="my-1 text-white">CMS version: {process.env.REACT_APP_VERSION}</small>
                    </Container>
                </div>

                <div
                    style={{
                        position: "fixed",
                        right: "1%",
                        bottom: "1%",
                        zIndex: "999",
                        display: this.state.showVersionToast ? "block" : "none"
                    }}
                >
                    <Toast className="animated fadeIn" show={this.state.showVersionToast} onClose={this.toggleVersionToast}>
                        <Toast.Header>
                            <strong className="mr-auto">New CMS Version</strong>
                            <small className="mr-2"> {this.props.version}</small>
                        </Toast.Header>
                        <Toast.Body>
                            Please refresh your browser &nbsp;{" "}
                            <Button size="sm" variant="warning" onClick={this.refreshApp}>
                                Refresh
                            </Button>
                        </Toast.Body>
                    </Toast>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    userAuth: state.general.userAuth,
    version: state.general.version
});

export default connect(
    mapStateToProps,
    null
)(withRouter(Sidebar));
