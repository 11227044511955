import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import history from "app/routes/history";
import axios from "axios";

// User Auth
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";

// Apollo
import { Query } from "react-apollo";
import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";

// Components
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Sidebar from "components/sidebar/Sidebar";
import Navbar from "components/navbar/Navbar";

// Global imports
import "app/misc/imports";

// All page routes
import { routes } from "app/routes/routes";

// Redux Actions
import { setUserAuth, setAppVersion } from "store/actions/generalActions";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            client: new ApolloClient({
                uri: process.env.REACT_APP_BACKEND + "/graphql"
            }),
            initialRender: true
        };

        setInterval(this.loadVersion, 60000 * 2);
    }

    componentDidMount() {
        this.checkUserToken();
        this.loadVersion();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userAuth !== this.props.userAuth) {
            this.updateApolloClient();
        }
    }

    loadVersion = async () => {
        const { data } = await axios.get(process.env.REACT_APP_BACKEND + "/version");
        this.props.setAppVersion(data);
    };

    updateApolloClient() {
        const client = new ApolloClient({
            uri: process.env.REACT_APP_BACKEND + "/graphql",
            fetchOptions: {
                credentials: "include"
            },
            request: async operation => {
                this.checkUserToken();
                const cookies = new Cookies();
                const token = cookies.get("jwtToken");

                operation.setContext({
                    headers: {
                        authorization: token ? `JWT ${token}` : ""
                    }
                });
            }
        });

        this.setState({ client, initialRender: false });
    }

    checkUserToken = () => {
        const cookies = new Cookies();
        const token = cookies.get("jwtToken");

        if (!token) {
            this.props.setUserAuth(false);
            return this.setState({
                initialRender: false,
                client: new ApolloClient({
                    uri: process.env.REACT_APP_BACKEND + "/graphql"
                })
            });
        }

        const user = jwtDecode(token);
        const exp = user.exp;
        const now = Math.round(Date.now() / 1000);
        if (now >= exp) {
            this.props.setUserAuth(false);
            return this.setState({
                initialRender: false,
                client: new ApolloClient({
                    uri: process.env.REACT_APP_BACKEND + "/graphql"
                })
            });
        }
        this.props.setUserAuth(user);
    };

    render() {
        if (this.state.initialRender) return null;

        return (
            <ApolloProvider client={this.state.client}>
                <Router history={history}>
                    <div id="wrapper">
                        <Route path="*" render={props => (this.props.userAuth ? <Sidebar {...props} /> : null)} />
                        <div className={this.props.userAuth ? "page-content" : "page-content-no-side"}>
                            <Navbar />
                            {!this.props.userAuth && <Redirect to="/login" replace />}
                            <Switch>
                                {routes.map((route, index) => {
                                    return (
                                        <Route
                                            path={route.path}
                                            render={props => {
                                                const { userAuth, userDetail } = this.props;

                                                let variables = {};
                                                if (route.path.includes("/book/detail/")) {
                                                    variables = { id: parseInt(props.match.params.bookId, 10) };
                                                }

                                                if (route.path.includes("/chapter/detail/")) {
                                                    variables = { id: parseInt(props.match.params.chapterId, 10) };
                                                }

                                                if (route.path.includes("/section/detail/")) {
                                                    variables = { id: parseInt(props.match.params.sectionId, 10) };
                                                }

                                                if (route.path.includes("/question/detail/")) {
                                                    variables = { id: parseInt(props.match.params.questionId, 10) };
                                                }

                                                if (route.path.includes("/ask-question/detail/")) {
                                                    variables = { id: parseInt(props.match.params.askQuestionId, 10) };
                                                }

                                                if (route.path.includes("/educator/detail/")) {
                                                    variables = { id: parseInt(props.match.params.educatorId, 10) };
                                                }

                                                if (route.path.includes("/student/detail/")) {
                                                    variables = { id: parseInt(props.match.params.studentId, 10) };
                                                }

                                                if (route.path.includes("/test-prep/book/detail/")) {
                                                    variables = { id: parseInt(props.match.params.testprepBookId, 10) };
                                                }

                                                if (route.path.includes("/test-prep/chapter/detail/")) {
                                                    variables = { id: parseInt(props.match.params.testprepChapterId, 10) };
                                                }

                                                if (route.path.includes("/test-prep/exam/detail/")) {
                                                    variables = { id: parseInt(props.match.params.testprepExamId, 10) };
                                                }

                                                if (route.path.includes("/affiliates/detail/")) {
                                                    variables = { id: parseInt(props.match.params.affiliateId, 10) };
                                                }

                                                if (route.path.includes("/referral/detail/")) {
                                                    variables = { id: parseInt(props.match.params.refereeId, 10) };
                                                }

                                                if (route.path.includes("/officehours/detail/")) {
                                                    variables = { id: parseInt(props.match.params.sectionId, 10) };
                                                }

                                                if (route.path.includes("/coursevideos/detail/")) {
                                                    variables = { id: parseInt(props.match.params.courseVideoId, 10) };
                                                }

                                                // Authentication required
                                                if (!props.location.pathname.includes("/login") && !userAuth) {
                                                    console.log("Redirected to login page from App.js");
                                                    return <Redirect to="/login" push />;
                                                }

                                                // Permission required
                                                if (userAuth && route.permissions) {
                                                    if (
                                                        !userDetail.permissions ||
                                                        !userDetail.permissions.some(perm => route.permissions.includes(perm))
                                                    ) {
                                                        return (
                                                            <div className="route">
                                                                <Alert variant="warning" style={{ borderRadius: "0px" }}>
                                                                    You don't have the correct permissions to view this page!
                                                                </Alert>
                                                            </div>
                                                        );
                                                    }
                                                }

                                                if (!route.query) {
                                                    return (
                                                        <div className="route">
                                                            <React.Suspense fallback={<Spinner />}>
                                                                <route.component {...props} />
                                                            </React.Suspense>
                                                        </div>
                                                    );
                                                }

                                                return (
                                                    <Query query={route.query} variables={variables}>
                                                        {({ loading, error, data }) => {
                                                            if (error) return null;

                                                            return (
                                                                <div className="route">
                                                                    {loading ? (
                                                                        <Spinner animation="border" role="status" className="loading xl">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </Spinner>
                                                                    ) : (
                                                                        <React.Suspense fallback={<Spinner />}>
                                                                            <route.component
                                                                                loading={loading}
                                                                                error={error}
                                                                                data={data}
                                                                                {...props}
                                                                            />
                                                                        </React.Suspense>
                                                                    )}
                                                                </div>
                                                            );
                                                        }}
                                                    </Query>
                                                );
                                            }}
                                            exact={!!route.exact}
                                            key={index}
                                        />
                                    );
                                })}
                            </Switch>
                        </div>
                    </div>
                </Router>
            </ApolloProvider>
        );
    }
}

const mapStateToProps = state => ({
    userAuth: state.general.userAuth,
    userDetail: state.general.userDetail
});

export default connect(mapStateToProps, { setUserAuth, setAppVersion })(App);
