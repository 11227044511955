import "bootstrap/dist/css/bootstrap.css";
import "styles/index.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faPlus,
    faSearch,
    faSortUp,
    faSortDown,
    faUserPlus,
    faUserCircle,
    faTrashAlt,
    faCrown,
    faEnvelope,
    faChevronDown
} from "@fortawesome/free-solid-svg-icons";

import "../../../node_modules/animate.css/animate.min.css";
import "katex/dist/katex.min.css";

library.add(faPlus, faSearch, faSortUp, faSortDown, faUserPlus, faUserCircle, faTrashAlt, faCrown, faEnvelope, faChevronDown);
